import { FormControlStatus } from '@angular/forms';

import { createActionGroup, createFeature, createReducer, createSelector, emptyProps, on, props } from '@ngrx/store';

import { selectDuplicate } from './patient-records.state';

export type PatientSearchForm = Record<string, any>;

export const PatientSearchFormActions = createActionGroup({
  source: 'Patient Search Form',
  events: {
    updatePatientSearchForm: props<PatientSearchForm>(),
    resetPatientSearchFormFields: emptyProps(),
    updateFormStatus: props<{ status: FormControlStatus }>(),
    markFieldsAsValid: emptyProps(),
    markFieldsAsInvalid: emptyProps(),
  },
});

export const PatientCommentsFormActions = createActionGroup({
  source: 'Patient Comments Form',
  events: {
    updatePatientRecordCommentsForm: props<{ comments: string }>(),
    resetPatientCommentsFormFields: emptyProps(),
  },
});

export interface PatientSearchFormState {
  fields: PatientSearchForm;
  formStatus: FormControlStatus;
  formFieldsValid: boolean;
  formFieldsInvalid: boolean;
  comments: string;
}

/**
 * tracking the form state in global state is necessary for submitting the form from the global footer
 * otherwise, the footer will need to be in every page component
 */

export const initialPatientSearchFormState: PatientSearchFormState = {
  fields: {},
  formStatus: 'INVALID', // start at invalid since all fields are required
  formFieldsValid: true, // start as valid since no updates happened yet
  formFieldsInvalid: false,
  comments: '',
};

export const patientSearchFormFeature = createFeature({
  name: 'patientSearchForm',
  reducer: createReducer(
    initialPatientSearchFormState,
    on(PatientSearchFormActions.updatePatientSearchForm, (state, { type, ...values }) => ({
      ...state,
      fields: {
        ...state.fields,
        ...values,
      },
    })),
    on(PatientSearchFormActions.updateFormStatus, (state, { status }) => ({
      ...state,
      formStatus: status,
    })),
    on(PatientSearchFormActions.markFieldsAsInvalid, (state) => ({
      ...state,
      formFieldsValid: false,
      formFieldsInvalid: true,
    })),
    on(PatientSearchFormActions.markFieldsAsValid, (state) => ({
      ...state,
      formFieldsValid: true,
      formFieldsInvalid: false,
    })),
    on(PatientSearchFormActions.resetPatientSearchFormFields, (state) => ({
      ...state,
      fields: {},
      formStatus: 'INVALID' as FormControlStatus,
      formFieldsValid: true,
      formFieldsInvalid: false,
    })),
    on(PatientCommentsFormActions.resetPatientCommentsFormFields, (state) => ({
      ...state,
      comments: '',
    })),
    on(PatientCommentsFormActions.updatePatientRecordCommentsForm, (state, { comments }) => ({
      ...state,
      comments,
    })),
  ),
  extraSelectors: ({ selectFields }) => ({
    selectPatientSearchFormFields: createSelector(selectFields, (fields) => fields),
  }),
});

export const {
  name: PATIENT_SEARCH_FORM_STATE_FEATURE_KEY,
  reducer: patientSearchFormReducer,
  selectFormStatus,
  selectPatientSearchFormFields,
  selectFormFieldsValid,
  selectFormFieldsInvalid,
  selectComments: selectPatientComments,
} = patientSearchFormFeature;

export const selectDuplicateRecordsFormViewModel = createSelector(
  selectDuplicate,
  selectPatientSearchFormFields,
  (duplicate, values) => ({ duplicate, values }),
);
