import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, inject, model, signal, ViewEncapsulation } from '@angular/core';

import { PushPipe } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { filter, switchMap, tap, timer } from 'rxjs';

import { INTERVAL_TO_EMIT_IN_MILLISECONDS, STARTING_VALUE_TO_EMIT } from '@abbadox-monorepo/kiosk-core-constants';
import { PatientRecordsPageActions } from '@abbadox-monorepo/kiosk-patient-data-access';
import { selectSessionTimeoutInSeconds } from '@abbadox-monorepo/kiosk-workflows-data-access';
import { IdsButton, IdsProgressBar } from '@abbadox-monorepo/shared-ui';

import { KioskDialogData, KioskDialogShell } from '../dialog-shell/dialog-shell.component';

export interface KioskPatientCheckinFailedDialogData extends KioskDialogData {
  maxTimeout: number;
  maxAttempts: number;
}

@Component({
  selector: 'kiosk-patient-checkin-failed-dialog',
  standalone: true,
  imports: [PushPipe, KioskDialogShell, IdsProgressBar, IdsButton],
  template: `
    <kiosk-dialog-shell [title]="title()" (closeClick)="handleCloseClickFromIcon()">
      <p class="message-line">We've failed to locate your record on today's schedule {{ maxAttempts() }} times.</p>
      <p class="message-line">Please restart from the beginning or go to the front desk to check in.</p>
      <p class="message-line">
        This session will automatically time out in
        @if (timer$ | ngrxPush) {}
        <span>{{ timerRemaining() }} seconds.</span>
      </p>
      <ids-progress-bar mode="determinate" [value]="progress()"></ids-progress-bar>
      <div class="dialog-actions-footer">
        <button ids-adc-button color="secondary" (click)="handleCloseClickFromButton()">Go to Start Page</button>
      </div>
    </kiosk-dialog-shell>
  `,
  styleUrls: ['./checkin-failed-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class KioskCheckinFailedDialog {
  readonly dialogRef = inject(DialogRef);
  readonly data = inject<KioskPatientCheckinFailedDialogData>(DIALOG_DATA);
  readonly store = inject(Store);

  /** Model changes for the dialog's content. */
  readonly title = model(this.data.title);
  readonly icon = model(this.data.iconName);
  readonly maxTimeout = model(this.data.maxTimeout);
  readonly maxAttempts = model(this.data.maxAttempts);

  /** Watcher for timer updates. */
  readonly timerRemaining = signal(0);
  readonly progress = signal(0);

  readonly timer$ = this.store.select(selectSessionTimeoutInSeconds).pipe(
    switchMap((maxTimeout) =>
      timer(STARTING_VALUE_TO_EMIT, INTERVAL_TO_EMIT_IN_MILLISECONDS).pipe(
        tap((countdown) => {
          this.timerRemaining.update(() => maxTimeout - countdown);
          this.progress.update(() => Math.ceil((countdown / maxTimeout) * 100));
        }),
        filter(() => this.progress() === 100),
        tap(() => this.returnToHome()),
      ),
    ),
  );

  handleCloseClickFromIcon() {
    this.dialogRef.close();
    this.store.dispatch(PatientRecordsPageActions.triggerCheckinFailedDialogCloseFromIcon());
  }

  handleCloseClickFromButton() {
    this.dialogRef.close();
    this.store.dispatch(PatientRecordsPageActions.triggerCheckinFailedDialogCloseFromButton());
  }

  returnToHome() {
    this.dialogRef.close();
    this.store.dispatch(PatientRecordsPageActions.triggerCheckinFailedDialogCloseFromTimeoutEvent());
  }
}
