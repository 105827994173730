import { hyphenate } from '@abbadox-monorepo/core-utils';
import {
  KioskConfiguration,
  WorkflowSelectorWidget,
  WorkflowsWithSteps,
} from '@abbadox-monorepo/kiosk-core-api-interfaces';

// Type after parsing kiosk configs into start page and workflows with steps
export type WorkflowSelector = WorkflowSelectorWidget & {
  idleTimeoutInSeconds: number;
  sessionTimeoutInSeconds: number;
  authRetrySessionTimeoutInSeconds: number;
  numberOfAuthRetryAttempts: number;
  startScreenWidgetSection: {
    heading: string | null;
    subHeading: string | null;
    startScreenWidgetSectionID: number;
    accentColor: string | null;
  };
};

export interface PageViewModel {
  startPage: WorkflowSelector;
  workflows: WorkflowsWithSteps[];
}

export const parseConfig = (config: KioskConfiguration): PageViewModel => {
  const { startScreenWidgets, ...timeoutAndRetrySettings } = config;
  const { startScreenWidgetSections, ...startScreenWidget } = startScreenWidgets[0];
  const { workflows, ...startScreenWidgetSection } = startScreenWidgetSections[0];

  return {
    startPage: {
      ...timeoutAndRetrySettings,
      ...startScreenWidget,
      startScreenWidgetSection: {
        ...startScreenWidgetSection,
      },
    },

    workflows: workflows.map((workflow) => ({
      ...workflow,
      action: {
        ...workflow.action,
        actionName: hyphenate(workflow.action.actionName),
      },
      steps: workflow.steps.map((step) => ({
        ...step,
        stepName: hyphenate(step.stepName),
      })),
    })),
  };
};
