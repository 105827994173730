import { HttpErrorResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, filter, map, of, switchMap, tap, withLatestFrom } from 'rxjs';

import { selectDeviceLocations } from '@abbadox-monorepo/kiosk-auth-data-access';
import { CommentedEntityType } from '@abbadox-monorepo/kiosk-core-api-interfaces';
import { PatientHttpService, selectPatientId, selectPatientIdMRN } from '@abbadox-monorepo/kiosk-patient-data-access';
import { KioskAppointmentsNotFoundDialog, KioskAppointmentsNotFoundDialogData } from '@abbadox-monorepo/kiosk-ui';
import {
  selectAllowedAppointmentStatusFilters,
  selectAppointmentStatusChangeUponCompletion,
  selectSessionTimeoutInSeconds,
} from '@abbadox-monorepo/kiosk-workflows-data-access';

import {
  AppointmentsApiActions,
  AppointmentsPageActions,
  selectAppointmentComment,
  selectAppointmentIds,
} from './appointments.state';
import { parseAppointments } from './parse-appointments';
import { AppointmentsHttpService } from './services/appointments-http-client.service';

/** Fetches the patient's scheduled appointments for the location they checking into. */
export const loadPatientAppointments$ = createEffect(
  (actions$ = inject(Actions), store = inject(Store), appointmentsHttpService = inject(AppointmentsHttpService)) =>
    actions$.pipe(
      ofType(
        AppointmentsPageActions.navigateToAppointments,
        AppointmentsApiActions.fetchAppointmentsAttempted,
        AppointmentsApiActions.fetchAppointmentsAttemptedFromRouteGuard,
      ),
      withLatestFrom(
        store.select(selectPatientId),
        store.select(selectAllowedAppointmentStatusFilters),
        store.select(selectDeviceLocations),
      ),
      switchMap(([, patientId, statusFilters, locationFilters]) =>
        appointmentsHttpService.findAppointments({ patientId, statusFilters, locationFilters }).pipe(
          map((appointments) =>
            AppointmentsApiActions.fetchAppointmentsSuccess({ appointments: parseAppointments(appointments) }),
          ),
          catchError((error: HttpErrorResponse) =>
            of(AppointmentsApiActions.fetchAppointmentsFailed({ error: error.message })),
          ),
        ),
      ),
    ),
  { functional: true },
);

/** Notifiy the user when authentication fails. */
export const notifyNoAppointmentsFound$ = createEffect(
  (actions$ = inject(Actions), store = inject(Store), dialog = inject(MatDialog)) =>
    actions$.pipe(
      ofType(AppointmentsApiActions.fetchAppointmentsFailed),
      withLatestFrom(store.select(selectSessionTimeoutInSeconds)),
      tap(([, maxTimeout]) => {
        dialog.open<KioskAppointmentsNotFoundDialog, KioskAppointmentsNotFoundDialogData>(
          KioskAppointmentsNotFoundDialog,
          {
            data: { title: 'No Appointments Today', maxTimeout },
          },
        );
      }),
    ),
  { functional: true, dispatch: false },
);

/** Adds appoinment comments. */
export const addAppointmentComments$ = createEffect(
  (actions$ = inject(Actions), store = inject(Store), patientHttpService = inject(PatientHttpService)) =>
    actions$.pipe(
      ofType(AppointmentsApiActions.updateAppointmentsStatusAttempted),
      withLatestFrom(
        store.select(selectPatientId),
        store.select(selectPatientIdMRN),
        store.select(selectAppointmentIds),
        store.select(selectAppointmentComment),
      ),
      filter(([, patientId, patientMRN, appointmentIDs, commentText]) =>
        Boolean(patientId && patientMRN && appointmentIDs.length && commentText),
      ),
      switchMap(([, patientId, patientMRN, appointmentIDs, commentText]) =>
        patientHttpService
          .updatePatientRecordsComments(String(patientId), {
            patientMRN,
            commentType: 'appointment',
            commentText,
            appointmentIDs,
            commentedEntityType: CommentedEntityType.Appointment,
          })
          .pipe(
            map(() => AppointmentsApiActions.submitAppointmentsCommentSuccess()),
            catchError((error) => of(AppointmentsApiActions.submitAppointmentsCommentFailed({ error }))),
          ),
      ),
    ),
  { functional: true },
);

/**
 * Update appointment(s) status.
 *
 * Occurs at the end of the check-in workflow.
 */
export const updateAppointmentsStatus$ = createEffect(
  (actions$ = inject(Actions), store = inject(Store), appointmentsHttpService = inject(AppointmentsHttpService)) =>
    actions$.pipe(
      ofType(AppointmentsApiActions.updateAppointmentsStatusAttempted),
      withLatestFrom(store.select(selectAppointmentIds), store.select(selectAppointmentStatusChangeUponCompletion)),
      switchMap(([, appointmentIds, status]) =>
        appointmentsHttpService.updateAppointmentsStatuses({ ids: appointmentIds, status }).pipe(
          map(() => AppointmentsApiActions.updateAppointmentsStatusSuccess()),
          catchError((error) => of(AppointmentsApiActions.updateAppointmentsStatusFailed({ error }))),
        ),
      ),
    ),
  { functional: true },
);
