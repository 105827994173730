import { HttpErrorResponse } from '@angular/common/http';
import { inject } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';

import { IdleActions } from '@abbadox-monorepo/core-idle';

import { WorkflowsHttpClientService } from './services/workflows-http-client.service';
import { parseConfig } from './workflows.parser';
import { WorkflowsApiActions } from './workflows.state';

/** Loads workflows. */
export const loadWorkflows$ = createEffect(
  (actions$ = inject(Actions), workflowsHttpClientService = inject(WorkflowsHttpClientService)) =>
    actions$.pipe(
      ofType(WorkflowsApiActions.loadWorkflowsAttempted),
      switchMap(() =>
        workflowsHttpClientService.getPages().pipe(
          map((config) => WorkflowsApiActions.loadWorkflowsSuccess(parseConfig(config))),
          catchError((error: HttpErrorResponse) =>
            of(WorkflowsApiActions.loadWorkflowsFailed({ error: error.message })),
          ),
        ),
      ),
    ),
  { functional: true },
);

/** Set idle params from kiosk configs. */
export const setIdleTimeoutParams$ = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(
      ofType(WorkflowsApiActions.loadWorkflowsSuccess),
      map(({ startPage }) => IdleActions.setIdleTimeout({ idleTimeoutSeconds: startPage.idleTimeoutInSeconds })),
    ),
  { functional: true },
);
