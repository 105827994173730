import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { createActionGroup, createFeature, createReducer, createSelector, emptyProps, on, props } from '@ngrx/store';

import { selectLogo } from '@abbadox-monorepo/kiosk-auth-data-access';
import { WorkflowsWithSteps } from '@abbadox-monorepo/kiosk-core-api-interfaces';

import { WorkflowSelector } from './workflows.parser';

export enum WORKFLOW_STATES {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  RESTARTED = 'RESTARTED',
}

export const WorkflowsApiActions = createActionGroup({
  source: 'Workflows Api',
  events: {
    loadWorkflowsAttempted: emptyProps(),
    loadWorkflowsSuccess: props<{ startPage: WorkflowSelector; workflows: WorkflowsWithSteps[] }>(),
    loadWorkflowsFailed: props<{ error: any }>(),
  },
});

export const WorkflowUIActions = createActionGroup({
  source: 'Workflow UI',
  events: {
    setWorkflow: props<{ currentWorkflow: string }>(),
    setCurrentStep: props<{ currentStep: string | undefined }>(),
    setNextStep: props<{ nextStep: string | undefined }>(),
    setPrevStep: props<{ prevStep: string | undefined }>(),
    setWorkflowState: props<{ workflowState: WORKFLOW_STATES }>(),
    navigateToWorkflowStartFromSessionIdleTimeout: emptyProps(),
    navigateToHomeFromPatientAuthCheckFailedDialogClosed: emptyProps(),
    navigateToHomeFromAppointentsNotFoundDialogTimeoutEvent: emptyProps(),
    navigateToHomeFromConfirmationTimeoutEvent: emptyProps(),
    navigateToWokflowStartFromConfirmationButtonClick: emptyProps(),
    navigateToWorkflowStartFromRestartFooterButtonClick: emptyProps(),
    navigateToWorkflowStartFromBackFooterButtonClick: emptyProps(),
    navigateToConfirmationPage: emptyProps(),
  },
});

export interface PageState {
  workflowSelector: WorkflowSelector;
  workflows: EntityState<WorkflowsWithSteps> & {
    selectedWorkflow: string;
    currentStep: string | undefined;
    nextStep: string | undefined;
    prevStep: string | undefined;
  };
  workflowState: WORKFLOW_STATES;
  loaded: boolean;
  error: any;
}

const selectWorkflowName = (a: WorkflowsWithSteps): string => {
  // Although id can be the primary key, we want to set the page's route as the primary key and lookup id.
  return a.action.actionName;
};

const workflowsAdapter: EntityAdapter<WorkflowsWithSteps> = createEntityAdapter<WorkflowsWithSteps>({
  selectId: selectWorkflowName,
});

export const initialPageState: PageState = {
  workflowSelector: {
    startScreenWidgetId: 0,
    heading: '',
    subHeading: '',
    icon: '',
    iconColor: '',
    idleTimeoutInSeconds: 0,
    sessionTimeoutInSeconds: 0,
    authRetrySessionTimeoutInSeconds: 0,
    numberOfAuthRetryAttempts: 0,
    startScreenWidgetSection: {
      heading: '',
      subHeading: '',
      startScreenWidgetSectionID: 0,
      accentColor: '',
    },
  },
  workflows: workflowsAdapter.getInitialState({
    selectedWorkflow: '',
    currentStep: '',
    nextStep: undefined,
    prevStep: undefined,
  }),
  workflowState: WORKFLOW_STATES.NOT_STARTED,
  loaded: false,
  error: null,
};

export const workflowsFeature = createFeature({
  name: 'workflows',
  reducer: createReducer(
    initialPageState,
    on(WorkflowsApiActions.loadWorkflowsSuccess, (state, { startPage, workflows }) => ({
      ...state,
      workflowSelector: startPage,
      workflows: workflowsAdapter.setAll(workflows, {
        ...state.workflows,
      }),
      loaded: true,
    })),
    on(WorkflowsApiActions.loadWorkflowsFailed, (state) => ({
      ...state,
      loaded: false,
    })),
    on(WorkflowUIActions.setWorkflow, (state, { currentWorkflow }) => ({
      ...state,
      workflows: {
        ...state.workflows,
        selectedWorkflow: currentWorkflow,
        workflowStarted: currentWorkflow ? WORKFLOW_STATES.IN_PROGRESS : WORKFLOW_STATES.NOT_STARTED,
      },
    })),
    on(WorkflowUIActions.setCurrentStep, (state, { currentStep }) => ({
      ...state,
      workflows: {
        ...state.workflows,
        currentStep,
      },
    })),
    on(WorkflowUIActions.setNextStep, (state, { nextStep }) => ({
      ...state,
      workflows: {
        ...state.workflows,
        nextStep,
      },
    })),
    on(WorkflowUIActions.setWorkflowState, (state, { workflowState }) => ({
      ...state,
      workflowState,
    })),
    on(WorkflowUIActions.setPrevStep, (state, { prevStep }) => ({
      ...state,
      workflows: {
        ...state.workflows,
        prevStep,
      },
    })),
    on(WorkflowUIActions.navigateToWorkflowStartFromRestartFooterButtonClick, (state) => ({
      ...state,
      workflows: {
        ...state.workflows,
        selectedWorkflow: '',
        currentStep: '',
        nextStep: undefined,
        prevStep: undefined,
      },
    })),
  ),
  extraSelectors: ({ selectWorkflowSelector, selectWorkflows }) => ({
    ...workflowsAdapter.getSelectors(selectWorkflows),
    selectIdleTimeoutInSeconds: createSelector(selectWorkflowSelector, (config) => config.idleTimeoutInSeconds),
    selectSessionTimeoutInSeconds: createSelector(selectWorkflowSelector, (config) => config.sessionTimeoutInSeconds),
    selectNumberOfAuthRetryAttempts: createSelector(
      selectWorkflowSelector,
      (config) => config.numberOfAuthRetryAttempts,
    ),
    selectAuthRetrySessionTimeoutInSeconds: createSelector(
      selectWorkflowSelector,
      (config) => config.authRetrySessionTimeoutInSeconds,
    ),
    selectSelectedWorkflow: createSelector(selectWorkflows, ({ selectedWorkflow }) => selectedWorkflow),
    selectCurrentStep: createSelector(selectWorkflows, ({ currentStep }) => currentStep),
    selectNextStep: createSelector(selectWorkflows, ({ nextStep }) => nextStep),
  }),
});

export const {
  name: WORKFLOWS_STATE_FEATURE_KEY,
  reducer: workflowsReducer,
  selectWorkflowSelector,
  selectIdleTimeoutInSeconds,
  selectSessionTimeoutInSeconds,
  selectNumberOfAuthRetryAttempts,
  selectAuthRetrySessionTimeoutInSeconds,
  selectSelectedWorkflow,
  selectCurrentStep,
  selectNextStep,
  selectWorkflowState,
  selectAll: selectWorkflows,
  selectEntities: selectWorkflowsEntities,
  selectLoaded: selectWorkflowLoaded,
} = workflowsFeature;

export const homePageViewModel = createSelector(
  selectWorkflowSelector,
  selectWorkflows,
  (startWidget, workflowSelctor) => ({ startWidget, workflowSelctor }),
);

/** Selects a workflow. */
export const selectWorkflow = createSelector(
  selectSelectedWorkflow,
  selectWorkflowsEntities,
  (worflow, entities) =>
    entities[worflow] ?? {
      workflowId: 0,
      sortOrder: 0,
      appointmentStatusChangeUponCompletion: null,
      stepId: 0,
      stepName: '',
      stepWidgets: [],
      action: {
        actionId: 0,
        actionName: '',
        buttonName: '',
        icon: '',
      },
      steps: [],
      allowableStatuses: [],
    },
);
export const selectAllowedAppointmentStatusFilters = createSelector(selectWorkflow, (workflow) =>
  workflow.allowableStatuses.map((s) => s.statusId).join(','),
);

export const selectAppointmentStatusChangeUponCompletion = createSelector(
  selectWorkflow,
  (workflow) => workflow.appointmentStatusChangeUponCompletion!,
);

/** Selects the steps in a workflow. */
export const selectSteps = createSelector(selectWorkflow, (workflow) => workflow.steps);

/** Selects the active page based on the step name in a route. */
export const selectPage = createSelector(selectSteps, selectCurrentStep, (steps, currentStep) =>
  steps.find((step) => step.stepName === currentStep),
);

export const selectTotalSteps = createSelector(selectSteps, (steps) => steps.length);

/** Selects the active step number. */
export const selectActiveStep = createSelector(selectPage, (page) => page?.sortOrder || 0);

/** Selects the percent complete in a workflow. */
export const selectPercentComplete = createSelector(
  selectActiveStep,
  selectTotalSteps,
  (activeStep, totalSteps): number => Math.ceil((activeStep / totalSteps) * 100),
);

/** Assembles the header for a page to dynamically update based on the currently active page. */
export const selectPageHeader = createSelector(
  selectLogo,
  selectWorkflow,
  selectPage,
  selectPercentComplete,
  (logo, workflow, page, percentage) => ({
    logo,
    stepTitle: workflow.action.actionName,
    stepName: page?.stepName,
    percentage: page?.stepName === 'confirmation' ? 0 : percentage,
  }),
);

/** Assembles the footer for a page to dynamically update based on the currently active page. */
export const selectPageFooter = createSelector(
  selectCurrentStep,
  selectSteps,
  selectPage,
  (currentStep, steps, page) => {
    const homePage = currentStep === undefined;
    const stepIndex = page ? page.sortOrder - 1 : 0;
    const next = steps[stepIndex + 1];
    const prev = steps[stepIndex - 1];

    if (!page) {
      return {
        step: '',
        prevStep: '',
        nextStep: '',
        actions: false,
        homePage,
      };
    }

    if (page?.stepName === 'confirmation') {
      return {
        step: page?.stepName,
        nextStep: undefined,
        prevStep: undefined,
        actions: false,
        homePage,
      };
    }

    return {
      step: page?.stepName,
      nextStep: next ? next.stepName : undefined,
      prevStep: prev ? prev.stepName : undefined,
      actions: Boolean(next ?? prev),
      homePage,
    };
  },
);

export const selectPageWidgets = createSelector(selectPage, (page) => page?.stepWidgets);

export const selectPageWidget = createSelector(selectPageWidgets, (content) =>
  content
    ? content[0]
    : {
        isActive: false,
        iconOverride: null,
        sortOrder: 0,
        stepWidgetId: 0,
        headingOverride: '',
        subHeadingOverride: null,
        accentColorOverride: null,
        failureMessageOverride: null,
        widget: {
          heading: '',
          subHeading: null,
          icon: null,
          isActive: false,
          widgetId: 0,
          widgetName: '',
          accentColor: '',
          failureMessage: '',
        },
      },
);

export const selectPageFailureMessage = createSelector(
  selectPageWidget,
  (widget) => widget.failureMessageOverride ?? widget.widget.failureMessage,
);
