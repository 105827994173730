import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, inject, model, signal, ViewEncapsulation } from '@angular/core';

import { PushPipe } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { filter, switchMap, tap, timer } from 'rxjs';

import { STARTING_VALUE_TO_EMIT, INTERVAL_TO_EMIT_IN_MILLISECONDS } from '@abbadox-monorepo/kiosk-core-constants';
import { selectSessionTimeoutInSeconds, WorkflowUIActions } from '@abbadox-monorepo/kiosk-workflows-data-access';
import { IdsButton, IdsProgressBar } from '@abbadox-monorepo/shared-ui';

import { KioskDialogData, KioskDialogShell } from '../dialog-shell/dialog-shell.component';

export interface KioskAppointmentsNotFoundDialogData extends KioskDialogData {
  maxTimeout: number;
}

@Component({
  selector: 'kiosk-appointments-not-found-dialog',
  standalone: true,
  imports: [PushPipe, KioskDialogShell, IdsButton, IdsProgressBar],
  template: `
    <kiosk-dialog-shell [title]="title()" (closeClick)="handleCloseClick()">
      <p class="message-line">
        We did not find appointments for you today. Please see the front desk.
        @if (timer$ | ngrxPush) {}
      </p>
      <ids-progress-bar mode="determinate" [value]="progress()"></ids-progress-bar>
    </kiosk-dialog-shell>
  `,
  styleUrls: ['./appointments-not-found-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class KioskAppointmentsNotFoundDialog {
  readonly dialogRef = inject(DialogRef);
  readonly data = inject<KioskAppointmentsNotFoundDialogData>(DIALOG_DATA);
  readonly store = inject(Store);

  /** Model changes for the dialog's content. */
  readonly title = model(this.data.title);
  readonly icon = model(this.data.iconName);
  readonly maxTimeout = model(this.data.maxTimeout);

  /** Watcher for timer updates. */
  readonly timerRemaining = signal(0);
  readonly progress = signal(0);

  readonly timer$ = this.store.select(selectSessionTimeoutInSeconds).pipe(
    switchMap((maxTimeout) =>
      timer(STARTING_VALUE_TO_EMIT, INTERVAL_TO_EMIT_IN_MILLISECONDS).pipe(
        tap((countdown) => {
          this.timerRemaining.update(() => maxTimeout - countdown);
          this.progress.update(() => Math.ceil((countdown / maxTimeout) * 100));
        }),
        filter(() => this.progress() === 100),
        tap(() => this.returnToHome()),
      ),
    ),
  );

  handleCloseClick() {
    this.dialogRef.close();
  }

  returnToHome() {
    this.dialogRef.close();
    this.store.dispatch(WorkflowUIActions.navigateToHomeFromAppointentsNotFoundDialogTimeoutEvent());
  }
}
