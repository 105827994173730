import { inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, tap, withLatestFrom } from 'rxjs';

import { IdleActions } from '@abbadox-monorepo/core-idle';
import { KioskPatientSessionTimeoutDialog, KioskSessionTimeoutDialogData } from '@abbadox-monorepo/kiosk-ui';
import { selectSessionTimeoutInSeconds, WorkflowUIActions } from '@abbadox-monorepo/kiosk-workflows-data-access';

/** Opens the session timeout dialog when idle timeout triggers. */
export const openSessionTimeoutDialog$ = createEffect(
  (actions$ = inject(Actions), store = inject(Store), dialog = inject(MatDialog)) =>
    actions$.pipe(
      ofType(IdleActions.triggerTimeoutDialogOpen),
      withLatestFrom(store.select(selectSessionTimeoutInSeconds)),
      tap(([, maxTimeout]) =>
        dialog.open<KioskPatientSessionTimeoutDialog, KioskSessionTimeoutDialogData>(KioskPatientSessionTimeoutDialog, {
          data: { title: 'Idle Timeout', maxTimeout },
          disableClose: true, // stops outside click to close
        }),
      ),
    ),
  { functional: true, dispatch: false },
);

/** Close the session timeout dialog and restart the idle service. */
export const closeSessionTimeoutDialogFromCloseIcon$ = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(
      ofType(IdleActions.triggerTimeoutDialogCloseFromIcon, IdleActions.triggerTimeoutDialogCloseFromButton),
      map(() => IdleActions.startIdleWatch()),
    ),
  { functional: true },
);

/**
 * Close the session timeout dialog and navigate to home.
 *
 * Routing to home will cleanly restart the idle service when a patient retries authentication.
 */
export const closeSessionTimeoutDialogFromTimeoutEvent$ = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(
      ofType(IdleActions.triggerTimeoutDialogCloseFromTimeoutEvent),
      map(() => WorkflowUIActions.navigateToWorkflowStartFromSessionIdleTimeout()),
    ),
  { functional: true },
);
