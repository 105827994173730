import { inject } from '@angular/core';
import { Router } from '@angular/router';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { filter, map, mergeMap, tap, withLatestFrom } from 'rxjs';

import { hasSomeValues } from '@abbadox-monorepo/core-utils';
import { AppointmentsApiActions, selectAppointmentsLoaded } from '@abbadox-monorepo/kiosk-appointments-data-access';
import {
  WorkflowUIActions,
  selectNextStep,
  selectSelectedWorkflow,
} from '@abbadox-monorepo/kiosk-workflows-data-access';

import { PatientSearchFormActions, selectPatientSearchFormFields } from './patient-checkin-form.state';
import {
  PatientRecordsApiActions,
  PatientRecordsPageActions,
  selectDuplicate,
  selectPatientId,
  selectPatientLoaded,
} from './patient-records.state';

/**
 * Effects in this file function as an event bus for incoming API actions.
 * This means that route transitions get handled here while state is modified in API effects.
 *
 * These effects depend on the workflow name to properly set the correct route.
 * If page configs don't have a page for a route, they simply won't transition although calling an
 * API resource.
 */

/** Navigate to patient verification there are NO duplicate records. */
export const navigateToPatientDetailsAfterAppointmentsVerified$ = createEffect(
  (actions$ = inject(Actions), store = inject(Store), router = inject(Router)) =>
    actions$.pipe(
      ofType(AppointmentsApiActions.fetchAppointmentsSuccess),
      withLatestFrom(
        store.select(selectSelectedWorkflow),
        store.select(selectNextStep),
        store.select(selectDuplicate),
        store.select(selectPatientLoaded),
        store.select(selectPatientId),
      ),
      /**
       * only allow navigation from a successful search if there are:
       * - no duplicates
       * - records were loaded successfully
       * - a single patient record exists
       * - appointment(s) exists
       * - next step matches a logical next step
       */
      filter(
        ([, _, nextStep, duplicate, loaded, patientId]) =>
          !duplicate && loaded && Boolean(patientId) && /(information|details)/i.test(nextStep ?? ''),
      ),
      tap(([, workflow, nextStep, _, __, patientId]) => {
        router.navigate([workflow, nextStep, patientId]);
      }),
    ),
  { functional: true, dispatch: false },
);

// export const navigateToNewPatientForm$ = createEffect(
//   (actions$ = inject(Actions), store = inject(Store), router = inject(Router)) =>
//     actions$.pipe(
//       ofType(PatientRecordsPageActions.navigateToNewPatientForm),
//       withLatestFrom(
//         store.select(selectSelectedWorkflow),
//         store.select(selectNextStep),
//         store.select(selectDuplicate),
//         store.select(selectPatientLoaded),
//         store.select(selectPatientId),
//       ),
//       tap(([_, workflow, nextStep, duplicate, loaded, patientId]) => {
//         if (!duplicate && loaded && patientId && nextStep === 'patient-information') {
//           router.navigate([workflow, nextStep, patientId, 'new']);
//         }
//       }),
//     ),
//   { functional: true, dispatch: false },
// );

export const navigateToHomeFromCheckinFailedDialogClosed$ = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(
      ofType(
        PatientRecordsPageActions.triggerCheckinFailedDialogCloseFromButton,
        PatientRecordsPageActions.triggerCheckinFailedDialogCloseFromTimeoutEvent,
      ),
      mergeMap(() => [
        PatientSearchFormActions.resetPatientSearchFormFields(),
        WorkflowUIActions.navigateToHomeFromPatientAuthCheckFailedDialogClosed(),
      ]),
    ),
  { functional: true },
);

/** Handles any cleanup tasks when navigating away from the patient authentication page. */
export const resetPatientAuthFormFields$ = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(
      ofType(PatientRecordsApiActions.fetchPatientDetailsSuccess),
      mergeMap(() => [
        PatientSearchFormActions.resetPatientSearchFormFields(),
        PatientRecordsApiActions.resetPatientAuthRetryAttempts(),
      ]),
    ),
  { functional: true },
);

/** Set errors to display if the patient attmepts to nagivate without completing the form. */
export const checkPatientSearchFormErrors$ = createEffect(
  (actions$ = inject(Actions), store = inject(Store)) =>
    actions$.pipe(
      ofType(PatientRecordsApiActions.loadPatientRecordsAttempted),
      withLatestFrom(store.select(selectPatientSearchFormFields)),
      map(([, params]) => {
        if (hasSomeValues(params)) {
          return PatientSearchFormActions.markFieldsAsValid();
        }

        return PatientSearchFormActions.markFieldsAsInvalid();
      }),
    ),
  { functional: true },
);
