import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';

import { Store } from '@ngrx/store';
import { Observable, filter, take, tap } from 'rxjs';

import { PatientRecordsApiActions, selectPatientLoaded } from '../patient-records.state';

/** Load patient details. */
export const patientExistsGuard: CanActivateFn = (route): Observable<boolean> => {
  const store = inject(Store);

  return store.select(selectPatientLoaded).pipe(
    tap((loaded) => {
      if (!loaded) {
        store.dispatch(PatientRecordsApiActions.fetchPatientDetailsAttempted({ patientId: route.params['patientId'] }));
      }
    }),
    filter((loaded) => loaded),
    take(1),
  );
};
