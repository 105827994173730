import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createActionGroup, createFeature, createReducer, createSelector, emptyProps, on, props } from '@ngrx/store';

import { AppointmentGroup } from './parse-appointments';

export const AppointmentsApiActions = createActionGroup({
  source: 'Appointments Api',
  events: {
    fetchAppointmentsAttempted: props<{ patientId: number }>(),
    fetchAppointmentsAttemptedFromRouteGuard: props<{ patientId: number }>(),
    fetchAppointmentsSuccess: props<{ appointments: AppointmentGroup[] }>(),
    fetchAppointmentsFailed: props<{ error: string }>(),
    submitAppointmentsCommentAttempted: emptyProps(),
    submitAppointmentsCommentSuccess: emptyProps(),
    submitAppointmentsCommentFailed: props<{ error: string }>(),
    updateAppointmentsStatusAttempted: emptyProps(),
    updateAppointmentsStatusSuccess: emptyProps(),
    updateAppointmentsStatusFailed: props<{ error: string }>(),
  },
});

export const AppointmentsPageActions = createActionGroup({
  source: 'Appointments Page',
  events: {
    initAppointmentsState: emptyProps(),
    navigateToAppointments: emptyProps(),
    updateAppointmentsCommentForm: props<{ comment: string }>(),
  },
});

export interface AppointmentsState extends EntityState<AppointmentGroup> {
  appointmentsLoaded: boolean;
  comment: string;
  error: string;
}

const selectAppointmentId = (a: AppointmentGroup): string => {
  return a.appointmentId;
};

const appointmentsAdapter: EntityAdapter<AppointmentGroup> = createEntityAdapter<AppointmentGroup>({
  selectId: selectAppointmentId,
});

export const initialAppointmentsState: AppointmentsState = appointmentsAdapter.getInitialState({
  appointmentsLoaded: false,
  comment: '',
  error: '',
});

const coerceToNumbers = (data: Array<string | number>) => data?.map((x) => Number(x));

export const appointmentsFeature = createFeature({
  name: 'appointments',
  reducer: createReducer(
    initialAppointmentsState,
    on(AppointmentsApiActions.fetchAppointmentsSuccess, (state, { appointments }) =>
      appointmentsAdapter.setAll(appointments, {
        ...state,
        appointmentsLoaded: Boolean(appointments.length), // dynamically set the loaded state for the guard
      }),
    ),
    on(AppointmentsApiActions.submitAppointmentsCommentSuccess, (state) => ({
      ...state,
      comment: '',
    })),
    on(AppointmentsPageActions.updateAppointmentsCommentForm, (state, { comment }) => ({
      ...state,
      comment,
    })),
    on(
      AppointmentsApiActions.updateAppointmentsStatusSuccess,
      AppointmentsPageActions.initAppointmentsState,
      () => initialAppointmentsState,
    ),
  ),
  extraSelectors: ({ selectAppointmentsState, selectIds }) => ({
    ...appointmentsAdapter.getSelectors(selectAppointmentsState),
    selectAppointmentIds: createSelector(selectIds, (ids) => coerceToNumbers(ids)),
  }),
});

export const {
  name: APPOINTMENTS_STATE_FEATURE_KEY,
  reducer: appointmentsReducer,
  selectAppointmentsLoaded,
  selectAppointmentIds,
  selectAll: selectAppointments,
  selectEntities: selectAppointmentEntities,
  selectComment: selectAppointmentComment,
} = appointmentsFeature;
