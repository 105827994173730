import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';

import { Store } from '@ngrx/store';
import { Observable, filter, take, tap } from 'rxjs';

import { AppointmentsApiActions, selectAppointmentsLoaded } from '../appointments.state';

/** Load appointments details. */
export const appointmentsExistsGuard: CanActivateFn = (route): Observable<boolean> => {
  const store = inject(Store);

  return store.select(selectAppointmentsLoaded).pipe(
    tap((loaded) => {
      if (!loaded) {
        store.dispatch(
          AppointmentsApiActions.fetchAppointmentsAttemptedFromRouteGuard({ patientId: route.params['patientId'] }),
        );
      }
    }),
    filter((loaded) => loaded),
    take(1),
  );
};
