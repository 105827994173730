import { HttpErrorResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, filter, map, of, switchMap, tap, withLatestFrom } from 'rxjs';

import { CoreUiActions } from '@abbadox-monorepo/core-data-access';
import { hasAllValues } from '@abbadox-monorepo/core-utils';
import {
  AppointmentsApiActions,
  selectAppointmentIds,
  selectAppointmentsLoaded,
} from '@abbadox-monorepo/kiosk-appointments-data-access';
import { CommentedEntityType } from '@abbadox-monorepo/kiosk-core-api-interfaces';
import {
  KioskCheckinFailedDialog,
  KioskPatientCheckinFailedDialogData,
  KioskPatientNotFoundDialog,
} from '@abbadox-monorepo/kiosk-ui';
import {
  selectAuthRetrySessionTimeoutInSeconds,
  selectNumberOfAuthRetryAttempts,
  selectPageFailureMessage,
  selectSelectedWorkflow,
} from '@abbadox-monorepo/kiosk-workflows-data-access';
import { notificationStatusColorMap } from '@abbadox-monorepo/shared-ui';

import {
  PatientSearchFormActions,
  selectPatientComments,
  selectPatientSearchFormFields,
} from './patient-checkin-form.state';
import {
  PatientRecordsApiActions,
  selectAttempts,
  selectPatientId,
  selectPatientIdMRN,
  selectPatientLoaded,
} from './patient-records.state';
import { PatientHttpService } from './services/patient-records-http-client.service';

/**
 * Searches for patient records.
 *
 * Records lookup goes through the following steps:
 * 1. Initial lookup -> Success
 * 2. Initial lookup -> Duplicates Found -> Search W/ extra filters -> Success
 */
export const searchPatientRecords$ = createEffect(
  (actions$ = inject(Actions), store = inject(Store), patientHttpService = inject(PatientHttpService)) =>
    actions$.pipe(
      ofType(PatientRecordsApiActions.loadPatientRecordsAttempted),
      withLatestFrom(store.select(selectPatientSearchFormFields)),
      filter(([_, params]) => hasAllValues(params)),
      switchMap(([_, params]) =>
        patientHttpService.findPatients(params).pipe(
          map((patientRecord) => PatientRecordsApiActions.loadPatientRecordsSuccess(patientRecord)),
          catchError((error: HttpErrorResponse) =>
            of(PatientRecordsApiActions.loadPatientRecordsFailed({ error: error.message })),
          ),
        ),
      ),
    ),
  { functional: true },
);

/** Resets form fields after fetching a single record with no duplicates. */
export const resetPatientSearchFormFields$ = createEffect(
  (actions$ = inject(Actions), store = inject(Store)) =>
    actions$.pipe(
      ofType(PatientRecordsApiActions.loadPatientRecordsSuccess),
      withLatestFrom(store.select(selectPatientLoaded), store.select(selectAppointmentsLoaded)),
      filter(([, patientLoaded, appointmentsLoaded]) => patientLoaded && appointmentsLoaded),
      map(() => PatientSearchFormActions.resetPatientSearchFormFields()),
    ),
  { functional: true },
);

/** Checks patient appointments early on before getting them into a workflow. */
export const checkPatientHasAppointments$ = createEffect(
  (actions$ = inject(Actions), store = inject(Store)) =>
    actions$.pipe(
      ofType(PatientRecordsApiActions.loadPatientRecordsSuccess),
      withLatestFrom(store.select(selectPatientId)),
      filter(([, patientId]) => Boolean(patientId)),
      map(([, patientId]) => AppointmentsApiActions.fetchAppointmentsAttempted({ patientId })),
    ),
  { functional: true },
);

/** Increments retry attempts when records failed to load due to no patient records. */
export const incrementPatientAuthRetryAttempts$ = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(
      ofType(PatientRecordsApiActions.loadPatientRecordsFailed),
      map(() => PatientRecordsApiActions.incrementPatientAuthRetryAttempts()),
    ),
  { functional: true },
);

/** Notify regular check-in patient not found in a toast when an attempt is made. */
export const notifyPatientRecordsSearchFailedToast$ = createEffect(
  (actions$ = inject(Actions), store = inject(Store)) =>
    actions$.pipe(
      ofType(PatientRecordsApiActions.incrementPatientAuthRetryAttempts),
      withLatestFrom(
        store.select(selectSelectedWorkflow),
        store.select(selectNumberOfAuthRetryAttempts),
        store.select(selectAttempts),
        store.select(selectPageFailureMessage),
      ),
      filter(([, workflow, _, __]) => workflow === 'check-in'),
      filter(([, _, maxAttempts, attempts]) => attempts < maxAttempts),
      map(([, _, __, ___, customErrorMessage]) =>
        CoreUiActions.triggerToastOpen({
          data: {
            ...notificationStatusColorMap.get('error'),
            label: 'No Match Found',
            messages: [customErrorMessage],
          },
        }),
      ),
    ),
  { functional: true },
);

/** Notify walk-in patient not found in a dialog when an attempt is made. */
export const notifyWalkinPatientRecordsSearchFailedDialog$ = createEffect(
  (actions$ = inject(Actions), store = inject(Store), dialog = inject(MatDialog)) =>
    actions$.pipe(
      ofType(PatientRecordsApiActions.incrementPatientAuthRetryAttempts),
      withLatestFrom(
        store.select(selectSelectedWorkflow),
        store.select(selectNumberOfAuthRetryAttempts),
        store.select(selectAttempts),
      ),
      filter(([_, workflow, __, ___]) => workflow === 'walk-in'),
      filter(([_, __, maxAttempts, attempts]) => attempts < maxAttempts),
      tap(() => {
        dialog.open<KioskPatientNotFoundDialog>(KioskPatientNotFoundDialog, {
          data: { title: 'No Existing Patient Found' },
        });
      }),
    ),
  { functional: true, dispatch: false },
);

/** Notify patient not found in a dialog after max rety attempts exceeded. */
export const notifyPatientRecordsSearchFailedDialog$ = createEffect(
  (actions$ = inject(Actions), store = inject(Store), dialog = inject(MatDialog)) =>
    actions$.pipe(
      ofType(PatientRecordsApiActions.incrementPatientAuthRetryAttempts),
      withLatestFrom(
        store.select(selectSelectedWorkflow),
        store.select(selectNumberOfAuthRetryAttempts),
        store.select(selectAuthRetrySessionTimeoutInSeconds),
        store.select(selectAttempts),
      ),
      filter(([_, __, maxAttempts, ___, attempts]) => attempts === maxAttempts),
      tap(([_, __, maxAttempts, maxTimeout, ___]) => {
        dialog.open<KioskCheckinFailedDialog, KioskPatientCheckinFailedDialogData>(KioskCheckinFailedDialog, {
          data: { title: 'Authentication Failed', maxTimeout, maxAttempts },
        });
      }),
    ),
  { functional: true, dispatch: false },
);

/** Resets retry attempts when records are loaded without errors. */
export const resetPatientAuthRetryAttempts$ = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(
      ofType(PatientRecordsApiActions.loadPatientRecordsSuccess),
      map(() => PatientRecordsApiActions.resetPatientAuthRetryAttempts()),
    ),
  { functional: true },
);

/**
 * Loads a single patient record.
 *
 * Used after authentication and needs a patient id. If the app refreshes,
 * we don't want to have the patient go through authentication again.
 */
export const loadPatientDetails$ = createEffect(
  (actions$ = inject(Actions), patientHttpService = inject(PatientHttpService)) =>
    actions$.pipe(
      ofType(PatientRecordsApiActions.fetchPatientDetailsAttempted),
      switchMap(({ patientId }) =>
        patientHttpService.findPatient(String(patientId)).pipe(
          map((patient) => PatientRecordsApiActions.fetchPatientDetailsSuccess({ patient })),
          catchError((error) => of(PatientRecordsApiActions.fetchPatientDetailsFailed({ error }))),
        ),
      ),
    ),
  { functional: true },
);

/** Update a patient records comments. */
export const updatePatientRecordComments$ = createEffect(
  (actions$ = inject(Actions), store = inject(Store), patientHttpService = inject(PatientHttpService)) =>
    actions$.pipe(
      ofType(PatientRecordsApiActions.submitPatientRecordCommentsAttempted),
      withLatestFrom(
        store.select(selectPatientId),
        store.select(selectPatientIdMRN),
        store.select(selectAppointmentIds),
        store.select(selectPatientComments),
      ),
      filter(([, patientId, patientMRN, appointmentIDs, commentText]) =>
        Boolean(patientId && patientMRN && appointmentIDs.length && commentText),
      ),
      switchMap(([, patientId, patientMRN, appointmentIDs, commentText]) =>
        patientHttpService
          .updatePatientRecordsComments(String(patientId), {
            patientMRN,
            commentType: 'patient',
            commentText,
            appointmentIDs,
            commentedEntityType: CommentedEntityType.Appointment,
          })
          .pipe(
            map(() => PatientRecordsApiActions.submitPatientRecordCommentsSuccess()),
            catchError((error) => of(PatientRecordsApiActions.submitPatientRecordCommentsFailed({ error }))),
          ),
      ),
    ),
  { functional: true },
);
