import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';

import { Store } from '@ngrx/store';
import { map, take } from 'rxjs';

import { selectWorkflowLoaded, WorkflowsApiActions } from '../workflows.state';

export const workflowsGuard: CanActivateFn = () => {
  const store = inject(Store);

  return store.select(selectWorkflowLoaded).pipe(
    map((profile) => {
      if (!profile) {
        store.dispatch(WorkflowsApiActions.loadWorkflowsAttempted());
      }

      return true;
    }),
    take(1),
  );
};
